import {getCookie, setCookie} from "../init/cookie";
import unitQuote from "./quote";

const CART_COOKIE_NAME = "cart"

export class Cart {
    _bookNow = false;
    _ready = false;
    _fetching = false;
    _cartId = null;
    _cart = null;

    constructor(book_now = false, cart_id = null) {
        this._bookNow = book_now;
        if (!book_now) {
            this._cartId = cart_id ? cart_id : getCookie(CART_COOKIE_NAME)
        }

        this.saveCoupon()
    }

    saveCoupon() {
        const urlParams = new URLSearchParams(window.location.search);
        if(urlParams.has('coupon')){
            setCookie('coupon', urlParams.get('coupon'));
        }
    }

    fetchCart() {
        if (!this._cartId) {
            return this.createCart();
        }

        this._fetching = true;
        this._ready = false;

        return fetch(`/api/cart/${this._cartId}`)
            .then(response => response.json())
            .then(data => {
                this.cart = data
                this._fetching = false
                return data
            })
    }

    createCart(bookNow = false) {
        this._fetching = true;
        this._ready = false;

        return fetch('/api/cart/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(data => {
                if (data.status !== 200) {
                    throw new Error(data.message)
                }
                return data;
            })
            .then(data => data.json())
            .then(data => {
                this._cartId = data.id
                this.cart = data;
                this._fetching = false;
                console.log(this);

                if (!this._bookNow) {
                    setCookie(CART_COOKIE_NAME, data.id, 365)
                }

                return data
            })
            .catch(error => console.log(error))
    }

    get cart() {
        return new Promise((resolve, reject) => {
            if (this._ready && !this._fetching) {
                resolve(this._cart)
            } else if (!this._ready && !this._fetching) {
                this.fetchCart()
                    .then(cart => resolve(cart))
                    .catch(error => reject(error))
            } else {
                setTimeout(() => {
                    this.cart.then(resolve)
                }, 100)
            }
        })
    }

    set cart(value) {
        this._cart = value;
        this._ready = true;
        window.dispatchEvent(new CustomEvent('cart.updated', {detail: this}))
    }

    get items() {
        return this.cart.then(cart => cart.items)
    }

    get size() {
        return this.items.then(items => items.length)
    }

    get_item(item_id) {
        return this.items.then(items => items.find(item => item.id === item_id))
    }

    async get_item_quote(item_id) {
        const item = await this.get_item(item_id)
        if (!item) return null

        return unitQuote({
            unit_id: item.unit_id,
            type: item.unit_type,
            arrival_date: item.checkin,
            departure_date: item.checkout,
            adults: item.adults,
            children: item.children,
            addons: item.addons
        });
    }

    get_item_by_unit(unit_type, unit_id) {
        return this.items.then(items => items.find(item => item.unit_type === unit_type && item.unit_id === unit_id))
    }

    add_item(item) {
        if (!this._cartId) {
            return this.cart.then(() => this._add_item(item));
        }

        return this._add_item(item)
    }

    _add_item(item) {
        return fetch(`/api/cart/${this._cartId}/add_item`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                unit_id: parseInt(item.unit_id),
                unit_type: item.type,
                checkin: item.arrival_date,
                checkout: item.departure_date,
                adults: parseInt(item.adults),
                children: parseInt(item.children),
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw Error("error")
                }
                return response.json()
            })
            .then(data => {
                this.cart = data
            })
    }

    update_item(item_id, item) {
        return fetch(`/api/cart/${this._cartId}/update_item`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: item_id,
                adults: parseInt(item.adults),
                children: parseInt(item.children),
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw Error("error")
                }
                return response.json()
            })
            .then(data => {
                this.cart = data
                return this.get_item(item_id)
            })
    }

    remove_item(item_id) {
        fetch(`/api/cart/${this._cartId}/remove_item`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                item_id: item_id
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw Error("error")
                }
                return response.json()
            })
            .then(data => this.cart = data)
    }

    add_addon(item_id, addon) {
        return fetch(`/api/cart/${this._cartId}/add_addon`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                item_id: item_id,
                addon_id: addon.id,
                quantity: addon.quantity
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw Error("error")
                }
                return response.json()
            })
            .then(data => this.cart = data)
    }

    add_upsale_addon(item_id, addon) {
        return fetch(`/api/reservation/${this._cartId}/add_addon`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                item_id: item_id,
                addon_id: addon.id,
                quantity: addon.quantity
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw Error("error")
                }
                return response.json()
            })
            .then(data => this.cart = data)
    }

    remove_addon(item_id, addon) {
        return fetch(`/api/cart/${this._cartId}/remove_addon`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                item_id: item_id,
                addon_id: addon.id
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw Error("error")
                }
                return response.json()
            })
            .then(data => this.cart = data)
    }

    add_travel_insurance() {
        return fetch(`/api/cart/${this._cartId}/travel_insurance`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw Error("error")
                }
                return response.json()
            })
            .then(data => this.cart = data)
    }

    remove_travel_insurance(id) {
        return fetch(`/api/cart/${this._cartId}/travel_insurance`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: id,
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw Error("error")
                }
                return response.json()
            })
            .then(data => this.cart = data)
    }

    apply_promo_code(coupon) {
        return fetch(`/api/cart/${this._cartId}/promo_code`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                promo_code: coupon
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw Error("error")
                }
                return response.json()
            })
            .then(data => {
                this.cart = data
                if (data.promo_code_message) {
                    throw Error(data.promo_code_message)
                }
                return data;
            })
    }

   remove_promo_code() {
        return fetch(`/api/cart/${this._cartId}/promo_code`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw Error("error")
                }
                return response.json()
            })
            .then(data => this.cart = data)
    }

    checkout() {
        window.location.href = `/checkout/${this._cartId}/booked`
    }
}

export function book_now(item) {
    const bookNow = new Cart(true);
    return bookNow.add_item(item).then(() => bookNow)
}

export function compare_item_and_booking_item(item, booking_item) {
    return (
        item.unit_id === parseInt(booking_item.unit_id) &&
        item.unit_type === booking_item.type &&
        item.checkin === booking_item.arrival_date &&
        item.checkout === booking_item.departure_date &&
        item.adults === parseInt(booking_item.adults) &&
        item.children === parseInt(booking_item.children)
    )

}

const cartInstance = new Cart();
window.vltcart2 = cartInstance;

export default cartInstance
